<template lang="pug">
div(class="mt-8 text-center")
  div(class="text-caption mb-5 mx-auto" width="60")
    | Registre sus datos para poder acceder a todo
    | el contenido completo del portal
  v-card(
    class="mx-auto my-auto meteo-form"
    max-width="500"
    outlined)
    v-alert(
      v-if="errorMessage"
      border="top"
      color="red lighten-2"
      dark) {{errorMessage}}
    v-form(ref="form" v-model="valid" lazy-validation :id="formId")
      v-row
        v-col(class="py-0" cols="12")
          v-checkbox(
            v-model="legalPerson"
            label="Marque sí es persona Jurídica")
        v-col(class="py-0" cols="12")
          v-text-field(
            dense
            v-model="firstName"
            :rules="[rules.required]"
            :label="legalPerson === true ? '*Nombre(s) representante' : '*Nombre(s)'"
            required)
        v-col(class="py-0" cols="12")
          v-text-field(
            dense
            v-model="lastName"
            :rules="[rules.required]"
            :label="legalPerson === true ? '*Apellido(s) representante' : '*Apellido(s)'"
            required)
        v-col(class="py-0" cols="12")
          v-text-field(
            v-if="legalPerson === true"
            dense
            v-model="companyName"
            :rules="[rules.required]"
            label="*Nombre empresa"
            required)
        v-col(class="py-0" cols="12")
          v-select(
            :items="validIdentificationTypes"
            item-text="name"
            item-value="siigo_id"
            v-model="identificationType"
            dense
            :rules="[rules.required]"
            label="*Tipo de identificación"
            required)
        v-col(class="py-0" cols="12")
          v-text-field(
            v-model="identification"
            dense
            :rules="[rules.required]"
            label="*Número identificación"
            required)
        v-col(class="py-0" cols="12")
          v-text-field(
            v-model="phone"
            dense
            :rules="[rules.required, rules.maxPhone]"
            label="*Teléfono"
            required)
        v-col(class="py-0" cols="12")
          v-text-field(
            v-model="address"
            dense
            :rules="[rules.required]"
            label="*Dirección"
            required)
        v-col(class="py-0" cols="12")
          city-picker(v-model="selectedCity")
        v-col(class="mt-4 py-0" cols="12")
          v-text-field(
            v-model="email"
            dense
            :rules="[rules.required, rules.emailvalid]"
            label="*Correo Electrónico"
            required)
        v-col(class="py-0" cols="6")
          v-text-field(
            dense
            v-model="userName"
            :rules="[rules.required, rules.min]"
            label="*Nombre de usuario"
            required)
        v-col(class="py-0" cols="6")
          v-text-field(
            dense
            :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
            :rules="[rules.required, rules.min]"
            :type="show ? 'text' : 'password'"
            name="input-10-2"
            label="Contraseña"
            hint="Mínimo 8 carácteres con mínimo 1 mayúscula"
            v-model="password"
            class="input-group--focused"
            @click:append="show = !show")
            v-col(class="py-0" cols="12")
        v-col(class="pt-0 pb-6" cols="12" id="rut_file")
          v-file-input(
            prepend-inner-icon="mdi-paperclip"
            v-model="rut"
            accept="image/*, application/pdf"
            dense
            :rules="[legalPerson === false ? undefined : rules.required]"
            label="RUT(Registro Único Tributario)"
            required)
      motion-captcha(
        :form-id="formId"
        v-model="validatedCaptcha"
        :show-error="captchaError"
        class="cuadro_capcha")
      v-btn(
        :disabled="!valid || sending"
        color="success"
        class="mr-4 mt-4"
        @click="submit") Enviar

    ul(class="form-conditions text-caption mt-5 text-left")
      li - Los campos marcados con asterísco son obligatorios
    v-col(cols="12" class="pt-5 pb-0")
      h4(class="text-subtitle-1 pr-5") ¿Ya tiene una cuenta?
    v-btn(
      :disabled="!valid || sending"
      color="info"
      class="mr-4 mt-4"
      @click="openLoginForm") Inicie Sesión
</template>

<script>
import { mapState, mapActions } from 'vuex';
import { meteoCountry } from '@/config.js';
import MotionCaptcha from '@/components/ui/MotionCaptcha.vue';
import CityPicker from '@/components/ui/CityPicker.vue';
import formMixin from '@/mixins/formMixin.js';

export default {
  name: 'RegisterForm',
  components: {
    MotionCaptcha,
    CityPicker
  },
  mixins: [formMixin],
  data() {
    return {
      formId: 'register-form',
      legalPerson: false,
      currentCountry: meteoCountry,
      firstName: '',
      lastName: '',
      companyName: '',
      identificationType: null,
      identification: '',
      userName: '',
      phone: '',
      password: '',
      email: '',
      address: '',
      sending: false,
      valid: true,
      show: false,
      rut: null,
      selectedCity: '',
      rules: {
        required: (value) => !!value || 'Campo obligatorio.',
        min: (v) => v.length >= 8 || 'Mínimo 8 caracteres con mínimo 1 mayúscula',
        emailvalid: (v) => /.+@.+\..+/.test(v) || 'Debe ingresar un correo válido',
        maxPhone: (v) => v.length < 11 || 'Máximo 10 números',
      },
      errorMessage: '',
    };
  },
  computed: {
    ...mapState('subscription', ['siigoIdentifications']),
    validIdentificationTypes() {
      let items = null;
      if (this.legalPerson === true) {
        items = this.siigoIdentifications.filter((x) => x.name.includes('NIT'));
      } else {
        items = this.siigoIdentifications.filter((x) => !x.name.includes('NIT'));
      }
      return items;
    }
  },
  mounted() {
    // traer las categorias de identificacion siigo
    this.getSiigoIdentifications();
  },
  methods: {
    ...mapActions('subscription', ['getSiigoIdentifications']),
    submit() {
      const isValid = this.validate();
      if (isValid === false) {
        return;
      }
      // validar que el nombre de la empresa cuando es persona juridica
      if (this.legalPerson === true && this.companyName === '') {
        this.errorMessage = 'El nombre de la empresa es obligatorio para personas jurídicas';
        return;
      }
      // validar que se haya elegido una ciudad
      if (this.selectedCity === '') {
        this.errorMessage = 'Debe seleccionar una ciudad para completar el registro';
        return;
      }
      if (this.validatedCaptcha === false) {
        this.captchaError = true;
        return;
      }
      const {
        userName,
        password,
        email,
        firstName,
        lastName,
        companyName,
        phone,
        address,
        identificationType,
        selectedCity,
        identification,
        rut
      } = this;
      // marcar que se esta enviando
      this.sending = true;
      this.$store.dispatch('auth/registerUser', {
        userName,
        password,
        email,
        firstName,
        lastName,
        companyName,
        phone,
        address,
        identificationType,
        selectedCity,
        identification,
        rut
      })
        .then((resp) => {
          this.sending = false;
          if (resp !== 'success') {
            this.errorMessage = resp;
            // mover el contenido al inicio para mostrar el error
            const el = document.getElementsByClassName('v-dialog')[0];
            el.scrollTo(0, 0);
          } else {
            // si el registro es exitoso mostrar el formulario de logueo
            this.errorMessage = '';
            this.$bus.$emit('select-modal-form', {
              component: 'login-form',
              icon: 'mdi-account',
              title: 'Registro exitoso! inicie sesión para continuar'
            });
          }
        })
        .catch((err) => {
          console.log('entro login catch', err);
        });
    },
    openLoginForm() {
      this.$emit('response', 'loginItem');
    },
  }
};
</script>
<style>
.v-application .v-dialog__content .v-dialog {
  max-width: 480px !important;
}
#rut_file .v-input .v-input__prepend-outer  {
  display: none;
}
.v-application .text-caption {
  font-size: 16px !important;
}
</style>
