import VueJwtDecode from 'vue-jwt-decode';
import AuthService from '@/services/AuthService.js';

const auth = {
  namespaced: true,
  state: {
    status: '',
    email: localStorage.getItem('email') || '',
    user: localStorage.getItem('username') || '',
    accessToken: localStorage.getItem('accessToken') || '',
    refreshToken: localStorage.getItem('refreshToken') || '',
    errorMessage: '',
    cookieSetted: false
  },
  mutations: {
    TOGGLE_LOADING(state) {
      state.isLoading = !state.isLoading;
    },
    AUTH_SUCCESS(state, {
      email, username, accessToken, refreshToken, userId
    }) {
      state.status = 'success';
      state.email = email;
      state.user = username;
      localStorage.setItem('email', email);
      localStorage.setItem('username', username);
      localStorage.setItem('accessToken', accessToken);
      localStorage.setItem('refreshToken', refreshToken);
      localStorage.setItem('userId', userId);
    },
    LOGOUT(state) {
      state.email = '';
      state.user = '';
      localStorage.removeItem('email');
      localStorage.removeItem('username');
      localStorage.removeItem('accessToken');
      localStorage.removeItem('refreshToken');
      localStorage.removeItem('userId');
    },
  },
  actions: {
    toggleLoading({ commit }) {
      commit('TOGGLE_LOADING');
    },
    // Acción para obtner la cookie csrftoken
    setCookie({ state }) {
      return AuthService.getCSRFCookie()
        .then((resp) => {
          state.cookieSetted = true;
          console.log('success cookie', resp);
        })
        .catch((err) => {
          console.log('error cookie', err);
        });
    },
    // Acción para autenticar el usuario en el portal
    login({ commit }, { userName, password }) {
      return AuthService.login(userName, password)
        .then((resp) => {
          if (resp.data) {
            const accessToken = resp.data.access;
            const refreshToken = resp.data.refresh;
            const tokenData = VueJwtDecode.decode(accessToken);
            const { email } = tokenData;
            const { username } = tokenData;
            const { userId } = tokenData;
            // axios.defaults.headers.common.Authorization = token;
            commit('AUTH_SUCCESS', {
              email, username, accessToken, refreshToken, userId
            });
            commit('ui/SHOW_ALERT', {
              level: 'success',
              message: 'Ha ingresado a Meteocolombia correctamente'
            },
            { root: true });
            // return 'success';
          }
          return 'success';
        })
        .catch((err) => {
          if (err.response.status === 401) {
            return 'Usuario o contraseña incorrectos';
          }
          return `Ocurrió un error: ${err.response.status} - ${err.response.data.detail}`;
        });
    },
    // Acción para obtner la cookie csrftoken
    userLogout({ commit }) {
      commit('LOGOUT');
      commit('ui/SHOW_ALERT', {
        level: 'success',
        message: 'Salió de la página correctamente'
      },
      { root: true });
      // return AuthService.logout()
      //   .then((resp) => {
      //     commit('LOGOUT');
      //     console.log('success logout', resp);
      //   })
      //   .catch((err) => {
      //     commit('ui/SHOW_ALERT', {
      //       status: 'error',
      //       message: 'Error al salir de la página'
      //     },
      //     { root: true });
      //     console.log('error logout', err);
      //   });
    },
    // Acción para registrar un nuevo usuario en el portal
    registerUser({ commit }, {
      firstName,
      lastName,
      companyName,
      phone,
      email,
      userName,
      password,
      address,
      identificationType,
      selectedCity,
      identification,
      rut
    }) {
      return AuthService.register(
        firstName,
        lastName,
        phone,
        email,
        userName,
        password,
        address,
        identificationType,
        identification,
        rut,
        companyName,
        selectedCity
      )
        .then((resp) => {
          if (resp.data) {
            commit('ui/SHOW_ALERT', {
              level: 'success',
              message: '¡Usuario registrado exitosamente!'
            },
            { root: true });
            // si el usuario es registrado correctamente, ir ala vista de login
            // router.push('/login');
          }
          return 'success';
        })
        .catch((err) => {
          if (err.response.status === 400) {
            return 'Usuario ya existe';
          }
          return `Ocurrió un error: ${err.response.status} - ${err.response.data.detail}`;
        });
    },
  },
  getters: {
    isLoggedIn: (state) => !!state.user,
    showAuthItem: (state) => (requireAuth) => {
      // Decide si mostrar o no un item, colocar en directiva v-if
      const isLogged = !!state.user;
      let show = false;
      if (requireAuth === false) {
        show = true;
      } else if (isLogged === true) {
        show = true;
      } else {
        show = false;
      }
      return show;
    }
  }
};

export default auth;
