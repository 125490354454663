<template lang="pug">
  div(class="mt-5 text-center")
    div(class="text-caption mb-5 mx-auto" width="60")
      | Diligencie el formulario y a continuación lo contactará
      | uno de nuestros expertos
    v-card(
      class="mx-auto my-auto meteo-form"
      max-width="400"
      outlined)
      v-card(v-if="show" class="card-form" elevation="0")
        v-form(ref="form" v-model="valid" lazy-validation :id="formId")
          v-text-field(
            v-model="firstName"
            :rules="[rules.required]"
            label="*Nombre(s)"
            required)
          v-text-field(
            v-model="lastName"
            :rules="[rules.required]"
            label="*Apellido(s)"
            required)
          v-text-field(
            v-model="subject"
            :rules="[rules.required]"
            label="*Asunto"
            required)
          v-text-field(
            v-model="email"
            :rules="[rules.required, rules.emailvalid]"
            label="*Correo Electrónico"
            required)
          v-textarea(
            v-model="message"
            :rules="[rules.required]"
            solo
            name="input-7-4"
            label="*Escriba aquí su inquietud"
            elevation="0")
          motion-captcha(
            :form-id="formId"
            v-model="validatedCaptcha"
            :show-error="captchaError")
          v-btn(
            :disabled="!valid || buttonDisabled"
            color="success"
            class="mr-4 mt-4"
            @click="sendFaq") Enviar
          ul(class="form-conditions text-caption mt-5 text-left")
          li  Los campos marcados con asterísco son obligatorios
      v-alert.mt-5(
        v-if="formSent"
        border="top"
        :color="colorAlert"
        dark
      ) {{ mensajeAlerta }}
      v-card-text
      div(class="text-h6") METEOCOLOMBIA
      div(class="text-caption mb-1 mx-auto" width="60") Teléfono: +57 314 280 1245
</template>

<script>
import MotionCaptcha from '@/components/ui/MotionCaptcha.vue';
import formMixin from '@/mixins/formMixin.js';
import { serverApi } from '@/config';

export default {
  name: 'FaqForm',
  components: {
    MotionCaptcha
  },
  mixins: [formMixin],
  data() {
    return {
      show: true,
      buttonDisabled: false,
      formSent: false,
      formId: 'faq-form',
      firstName: '',
      lastName: '',
      subject: '',
      email: '',
      message: '',
      valid: true,
      rules: {
        required: (value) => !!value || 'Campo obligatorio.',
        min: (v) => v.length >= 8 || 'Minimo 8 caracteres',
        emailvalid: (v) => /.+@.+\..+/.test(v) || 'Debe ingresar un correo valido',
      },
    };
  },
  methods: {
    sendFaq() {
      this.buttonDisabled = true;
      const isValid = this.validate();
      if (isValid === false) {
        this.buttonDisabled = false;
        return;
      }
      if (this.validatedCaptcha === false) {
        this.buttonDisabled = false;
        this.captchaError = true;
        return;
      }
      this.captchaError = false;
      const url = `${serverApi}contact`;
      fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          firstName: this.firstName,
          lastName: this.lastName,
          subject: this.subject,
          email: this.email,
          message: this.message,
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          this.formSent = true;
          this.colorAlert = 'success';
          this.mensajeAlerta = 'Mensaje enviado con éxito!';
          // eslint-disable-next-line no-console
          console.log('Success:', data);
          this.show = false;
        })
        .catch((error) => {
          this.formSent = true;
          this.colorAlert = 'error';
          this.mensajeAlerta = 'Error al enviar el mensaje. Intente nuevamente más tarde';
          // eslint-disable-next-line no-console
          console.error('Error:', error);
          this.show = false;
        });
    }
  }
};
</script>

<style scoped>

.card-form {
  border: none;
}

</style>
