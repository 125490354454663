<template lang="pug">
div(class="mt-8 text-center")
  v-tabs(
    class="mt-2"
    color="deep-purple accent-4"
    left)
    v-tab Mis datos
    v-tab Creditos
    v-tab Contraseña
    //- Informacion del perfil de usuario
    v-tab-item(key="info")
      v-container(class="pt-0" fluid)
        account-info
    //- Información de los creditos que se posee
    v-tab-item(key="wallet")
      v-container(class="pt-0" fluid)
        meteo-wallet
    //- Cambio de contraseña
    v-tab-item(key="password")
      v-container(class="pt-0" fluid)
        change-password
</template>

<script>
import MeteoWallet from '@/components/subscription/MeteoWallet.vue';
import AccountInfo from '@/components/user/AccountInfo.vue';
import ChangePassword from '@/components/user/ChangePassword.vue';

export default {
  name: 'UserInfo',
  components: {
    MeteoWallet,
    AccountInfo,
    ChangePassword
  },
  data: () => ({
  })
};
</script>
