import MeteoService from '@/services/MeteoService.js';
import ProductService from '@/services/ProductService.js';
import { meteoCountry } from '../../config.js';

const meteocolombia = {
  namespaced: true,
  state: {
    loadingCanvas: false,
    selectedCity: null,
    cities: [],
    studies: {
      banner: {
        title: 'Estudios Micrometeorológicos',
        summary: 'El numeral 4.4.3 del PROTOCOLO PARA EL MONITOREO Y SEGUIMIENTO DE LA CALIDAD DEL AIRE pide un Estudio Micrometeorológico. Consulte en seguida la información mínima solicitada y la forma cómo Meteocolombia le garantiza el cumplimiento con cada requerimiento de la norma'
      },
      items: [
        {
          id: 1,
          title: 'Tendencias de viento anuales durante los últimos 5 a 10 años',
          description:
            'Series de tiempo de los máximos, mínimos y promedios mensuales para las variables Velocidad de viento, Temperatura a 2m y Humedad relativa a 2m. Para la precipitación y evaporación se presentan los acumulados mensuales. La presentación será en líneas para velocidad de viento, temperatura y humedad relativa y en barras para la precipitación y evaporación. Adicionalmente se entregan las series completas de las variables mencionadas en tablas en formato .csv.',
          image: 'https://static.geoentropica.com/CaliAire/Estudio.jpg',
        },
        {
          id: 2,
          title: 'Rosa de vientos diurna y nocturna',
          description:
            'Se generan mínimo cuatro rosas de viento, según la disponibilidad de datos observados, las diurnas con datos medidos entre las 7a.m u 7p.m durante todos los años y las nocturnas con las observaciones entre la 7p.m. Y 7a.m. Cada rosa de viento diurna y nocturna se genera para los sitios de la estaciónes con datos de observación de viento y el sitio del relleno sanitario. Las primeras con base en los datos del IDEAM y para el relleno sanitario con base en datos simulados para el periodo 01/01/2011 a 31/12/2015.',
          image: 'https://static.geoentropica.com/CaliAire/Estudio.jpg',
        },
        {
          id: 3,
          title: 'Variaciones mensuales de la rosa de viento',
          description:
            'Para cada uno de los meses enero a diciembre se elabora una rosa de viento generada con los datos medidos o simulados en cada uno de los doce meses durante varios años seguidos. Así se entregarán 12 rosas de vientos para cada sitio. Las rosas de viento se elaboran para los sitios de las estaciónes de observación y para el sitio del relleno sanitario.',
          image: 'https://static.geoentropica.com/CaliAire/Estudio.jpg',
        },
        {
          id: 4,
          title: 'Condiciones micrometeorológicas de vientos',
          description:
            'La forma de detectar climas locales, a lo que hace referencia el término “condiciones micrometeorológicas”, son mapas de las condiciones meteorológicas en superficie y a 2m de altura alrededor del sitio de estudio. Para prestar más que la información mínima solicitada se elaboran mapas para las variables velocidad de viento a 10m, temperatura, humedad relativa evaporación y precipitación. Todos los mapas se generan en formato geotiff. Temperatura, humedad relativa y precipitación se presentan con isolíneas y colores como muestra el ejemplo. Velocidad y dirección de viento se presentan con colores y líneas de corrientes parecido al ejemplo de la figura 3.',
          image: 'https://static.geoentropica.com/CaliAire/Estudio.jpg',
        },
        {
          id: 5,
          title: 'Tendencias de precipitación durante los últimos 5 a 10 años',
          description:
            'Como mencionado se entregan series de tiempo con los acumulados mensuales de precipitación. La presentación será en barras. Adicionalmente para la precipitación se presentan una gráfica con los promedios multianuales mensuales para destacar el ciclo anual',
          image: 'https://static.geoentropica.com/CaliAire/Estudio.jpg',
        },
        {
          id: 6,
          title: 'Identficación de épocas secas y húmedas',
          description:
            'Existen diferentes formas de definir condiciones secas. Usualmente se hace referencia al balance hídrido para determinar la oferta o déficit hídrico o se refiere a la humedad atmosférica en términos de humedad relativa, razón de mezcla o humedad específica. Para destacar épocas secas en términos de humedad atmosférica durante el año se generan las series de los promedios multianuales mensuales de la humedad relativa. Para analizar el balance hídrico se grafica la diferencia entre la precipitación mensual acumulada y la evapotranspiración para el sitio de interés. La evapotranspiración se calcula según la ecuación de Penmann-Monteith definida por la Organización de Alimetos y Agricultura de las Naciones Unidas (FAO)',
          image: 'https://static.geoentropica.com/CaliAire/Estudio.jpg',
        },
      ]
    },
    products: {
      banner: {
        title: 'Productos',
        summary: 'Los productos ofrececidos se basan en la información climatológica observada por la entidad oficial, el Instituto de Hidrología, Meteorología y Estudios Ambientales – IDEAM y se complementa con información climática simulada por Meteocolombia S.A.S. donde se considera necesario. Para el cálculo de los promedios multianuales se recomienda contar con series de 30 años y como mínimo series de 10 años. Eso con el fin de incluir diferentes fases de la variabilidad climática. Se selecionará la información disponible según estos estándares.'
      },
      items: [
        {
          id: 1,
          title: 'Rosas de viento',
          description:
            'Para cada estación climatológica ubicada en la zona de estudio que cuente con series de velocidad y dirección de viento de al menos 10 años se entrega la rosa de viento diurna y nocturna. En caso de la carencia de datos observados se generan rosas de vientos adicionales a partir de los datos simulados como muestra el ejemplo',
          image: 'https://static.geoentropica.com/CaliAire/80259_CALI_oni.png',
        },
        {
          id: 2,
          title: 'Tabla con máximos, mínimos, promedios',
          description:
            'Se entrega una tabla que contiene los máximos y mínimos mensuales para cada una de las variables mencionadas para cada estación donde fuesen medidas junto con los promedios mensuales multianuales y promedios anuales.',
          image: 'https://static.geoentropica.com/CaliAire/80259_CALI_oni.png',
        },
        {
          id: 3,
          title: 'Mapas climáticos',
          description:
            'Todos los mapas incluyen capas como municipios, departamentos y/o topografía según las preferencias del cliente. Temperatura, humedad relativa y precipitación se presentan con isolíneas y colores. Velocidad y dirección de viento se presentan con colores y líneas de corrientes. Se elaboran mapas de nubosidad a solicitud del cliente',
          image: 'https://static.geoentropica.com/CaliAire/80259_CALI_oni.png',
        },
        {
          id: 4,
          title: 'Control de Calidad de las series observadas',
          description:
            'Se aplica el análisis de la coherencia interna, espacial y temporal de las series medidas según los lineamientos del IDEAM y de la Organización mundial de Meteorología para el control de datos observados. Como resultados se entregan las series depuradas de las variables medidas en las estaciones ubicadas en la zona de interés. Con el fin de dar un criterio de acierto de los datos simulados se comparan las series medidas con las simuladas para las ubicaciones de cada estación y se calculan los coeficientes estadísticos. A la vez se comparan las rosas de viento simuladas y observadas',
          image: 'https://static.geoentropica.com/CaliAire/80259_CALI_oni.png',
        },
        {
          id: 5,
          title: 'Series observadas depuradas en tablas y gráficas',
          description:
            'Diagramas de las series observadas y simuladas en intervalos diarios, mensuales y anuales y series de los promedios multianuales mensuales',
          image: 'https://static.geoentropica.com/CaliAire/80259_CALI_oni.png',
        },
      ]
    },
    loginItem: {
      key: 'login',
      title: 'Ingresar a Meteocolombia',
      icon: 'mdi-account',
      description: 'Ingrese sus credenciales de usuario para poder acceder a todo el contenido',
      component: 'login-form',
      requireAuth: false,
    },
    registerItem: {
      key: 'register',
      title: 'Registrarse',
      icon: 'mdi-account-plus',
      description: 'Cree una cuenta para una persona o empresa',
      component: 'register-form',
      requireAuth: false,
    },
    logoutItem: {
      key: 'logout',
      title: 'Salir de Meteocolombia',
      icon: 'fa-sign-out-alt',
      description: 'presione para retirarse de la pagina',
      component: 'login-form',
      requireAuth: true,
    },
    forgotPassword: {
      key: 'forgot',
      title: 'Recuperar contraseña',
      icon: 'mdi-account-key',
      description: 'RECUPERAR CONTRASEÑA',
      component: 'forgot-password-form',
      requireAuth: false,
    },
    staticTopItems: [
      {
        key: 'credit',
        title: 'Cargar creditos',
        icon: 'mdi-cart',
        description: 'Adquiera créditos para las aplicaciones de Meteocolombia',
        component: 'credit-manager',
        requireAuth: false,
      },
    ],
    blogItem: {
      key: 'blog',
      title: 'Publicaciones',
      icon: 'fa-newspaper', // mdi-note-text
      description: 'Nueva información de Meteocolombia',
      component: 'MeteoBlog',
      requireAuth: false,
    },
    staticBottomItems: [
      /* {
        key: 'login',
        title: 'Ingresar a Meteocolombia',
        icon: 'fa-user', // mdi-frequently-asked-questions
        description: ' Ingrese sus credenciales de usuario para poder acceder a todo el contenido',
        component: 'login-form',
        requireAuth: false,
      }, */
      {
        key: 'credit',
        title: 'Carrito de compras',
        icon: 'fa-shopping-cart', // mdi-chat-question
        description: 'Adquiera créditos para las aplicaciones',
        component: 'credit-manager',
        requireAuth: false,
      },
      {
        key: 'nosotros',
        title: '¿Quienes Somos?',
        icon: 'fa-users ',
        description: 'Conozca más sobre Meteocolombia',
        url: '/pages/Nosotros',
        requireAuth: false,
      },
      {
        key: 'fichatec',
        title: 'Ficha Técnica',
        icon: 'fa-table', // mdi-chat-question
        description: 'Aquí encontrará respuesta a las preguntas más comunes',
        url: '/pages/FichaTec',
        requireAuth: false,
      },
      {
        key: 'terminos',
        title: 'Términos de Uso',
        icon: 'fa-list', // mdi-chat-question
        description: 'Aquí encontrará respuesta a las preguntas más comunes',
        url: '/pages/TermUso',
        requireAuth: false,
      },
      {
        key: 'faqs',
        title: 'Contáctenos',
        icon: 'fa-comments', // mdi-frequently-asked-questions
        description: 'CONTÁCTENOS',
        component: 'faq-form',
        requireAuth: false,
      },
      {
        key: 'faq',
        title: 'Preguntas frecuentes',
        icon: 'fa-question-circle', // mdi-chat-question
        description: 'Aquí encontrará respuesta a las preguntas más comunes',
        url: '/pages/FAQ',
        requireAuth: false,
      },
    ],
    // los paises en los cuales la empresa realiza pronosticos
    meteoCountries: [],
    // El pais actual de acuerdo a la variable meteoCountry en config.js
    currentCountry: null
  },
  mutations: {
    SET_COUNTRY(state, isoCode) {
      [state.currentCountry] = state.meteoCountries.filter((x) => x.alpha_3_iso_code === isoCode);
    },
    SET_CITY(state, city) {
      state.selectedCity = city;
    },
    SET_CITIES(state, cities) {
      // Añadir campo fullname a cada ciudad
      cities.forEach((city) => {
        city.fullname = `${city.name} - ${city.state_name}`;
      });
      state.cities = cities;
    },
    SET_LOADING_CANVAS(state, value) {
      state.loadingCanvas = value;
    },
  },
  actions: {
    getCities({ commit }, name) {
      return MeteoService.getCities(name)
        .then((resp) => {
          commit('SET_CITIES', resp.data);
        })
        .catch((err) => {
          console.error(err);
          commit('SHOW_ALERT', {
            message: 'Error al cargar las ciudades disponibles',
            level: 'error'
          });
        });
    },
    getCountries({ state, commit }) {
      return MeteoService.getCountries()
        .then((resp) => {
          state.meteoCountries = resp.data;
          commit('SET_COUNTRY', meteoCountry);
        })
        .catch((err) => {
          console.error(err);
          commit('SHOW_ALERT', {
            message: 'Error al cargar los países disponibles',
            level: 'error'
          });
        });
    },
    getPaymentService({ commit, rootState }, code) {
      return ProductService.getPaymentService(code)
        .then((resp) => {
          const { data } = resp;
          const { service } = data;
          rootState.subscription.buyerEmail = service.customer.email;
          rootState.subscription.buyerName = service.customer.full_name;
          rootState.subscription.serviceAmount = data.amount;
          rootState.subscription.amount = data.amount;
          rootState.subscription.referenceCode = data.name;
          rootState.subscription.tax = data.tax;
          rootState.subscription.taxReturnBase = data.taxReturnBase;
          rootState.subscription.serviceProducts = service.products;
          rootState.subscription.serviceValid = true;
          console.log('RESP PAGO', resp);
        })
        .catch((err) => {
          console.error(err);
          rootState.subscription.serviceValid = false;
          commit('ui/SHOW_ALERT', {
            message: 'Error al cargar el pago',
            level: 'error'
          }, { root: true });
        });
    },
  },
  getters: {
    getItem: (state) => (itemId, source) => {
      const item = state[source].items.filter((x) => x.id === itemId)[0];
      return item;
    }
  },
};

export default meteocolombia;
