import Vue from 'vue';
import VueRouter from 'vue-router';
// Vuex
import store from '../store';
import WebGis from '../views/WebGis.vue';
import Login from '../views/Login.vue';
import Services from '../views/Services.vue';
import ProductDescription from '../views/ProductDescription.vue';
import PlaceForecast from '../views/PlaceForecast.vue';
import ModelInfo from '../views/ModelInfo.vue';
import NotFound from '../views/NotFound.vue';
import FlatPage from '../views/FlatPage.vue';
import FrontEnd from '../views/FrontEnd.vue';
import ForecastDetail from '../views/ForecastDetail.vue';
import ResponsePage from '../views/ResponsePage.vue';
import PaymentService from '../views/PaymentService.vue';

import {
  webGisRoute, frontendRoute, defaultAppRoute, defaultAppType
} from '../config.js';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    redirect: () =>
      // the function receives the target route as the argument
      // we return a redirect path/location here.
      ({ path: `/fronts/${defaultAppRoute}`, query: { appType: defaultAppType } })
    ,
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/studies',
    name: 'Studies',
    component: Services
  },
  {
    path: '/products',
    name: 'Products',
    component: Services
  },
  {
    path: '/pages/:page',
    name: 'FlatPage',
    component: FlatPage
  },
  {
    path: `/${frontendRoute}/:app`,
    name: 'FrontEnd',
    component: FrontEnd
  },
  /* {
    path: '/test',
    name: 'Test',
    component: Test,
    meta: {
      requiresAuth: true
    }
  }, */
  {
    path: `/${webGisRoute}/:location`,
    name: 'WebGis',
    component: WebGis
  },
  {
    path: '/view-study/:item_id',
    name: 'StudyDescription',
    component: ProductDescription,
  },
  {
    path: '/view-product/:item_id',
    name: 'ProductDescription',
    component: ProductDescription,
  },
  {
    path: '/forecast/:point_token',
    name: 'Forecast',
    component: PlaceForecast
  },
  {
    path: '/models/:model_name',
    name: 'ModelInfo',
    component: ModelInfo
  },
  {
    path: '/detail/:elementId/:ghaphType',
    name: 'ForecastDetail',
    component: ForecastDetail
  },
  {
    path: '/payu/response',
    name: 'ResponsePage',
    component: ResponsePage,
  },
  {
    path: '/service_payment/:paymentCode',
    name: 'PaymentService',
    component: PaymentService
  },
  {
    path: '/:catchAll(.*)',
    component: NotFound,
  },
];

const router = new VueRouter({
  mode: 'history',
  base: `${process.env.BASE_URL}/`,
  routes
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (store.getters['auth/isLoggedIn']) {
      next();
      return;
    }
    next('/');
  } else {
    next();
  }
});

export default router;
