<template lang="pug">
div(class="mt-8 text-center")
  v-card(class="mx-auto my-auto meteo-form text-center" max-width="400" outlined)
    v-alert(
      v-if="errorMessage"
      border="top"
      color="red lighten-2"
      dark) {{errorMessage}}
    v-form(ref="form" v-model="valid" lazy-validation :id="formId")
      h4(class="text-normal text-center") Iniciar Sesión
      v-text-field(
        v-model="userName"
        :rules="nameRules"
        label="Usuario"
        required)
      v-text-field(
        :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
        :rules="[rules.required]"
        :type="show ? 'text' : 'password'"
        name="input-10-2"
        label="Contraseña"
        hint="At least 8 characters"
        v-model="password"
        class="input-group--focused"
        @click:append="show = !show")
      motion-captcha(
        :form-id="formId"
        v-model="validatedCaptcha"
        :show-error="captchaError"
        class="cuadro_capcha")
      v-row
        v-col(cols="12" class="pt-7")
          v-btn(
            :disabled="!valid"
            color="success"
            @click="login") Ingresar
        v-col(cols="12" class="pt-2")
          v-btn(class="text-forgot"
            text
            small
            color="primary"
            @click="openForgotPasswordForm"
          ) ¿Olvidó su contraseña?
        v-col(cols="12" class="pt-2")
          h4(class="text-subtitle-1 text-center") ¿No tiene una cuenta?
        v-col(cols="12" class="pt-0")
          v-btn(
            variant="text"
            color="info"
            @click="openRegisterForm") Regístrese
</template>

<script>
import { mapActions, mapMutations } from 'vuex';
import MotionCaptcha from '@/components/ui/MotionCaptcha.vue';
import formMixin from '@/mixins/formMixin.js';

export default {
  name: 'LoginForm',
  components: {
    MotionCaptcha
  },
  mixins: [formMixin],
  data() {
    return {
      formId: 'form-id',
      userName: '',
      valid: true,
      show: false,
      password: '',
      rules: {
        required: (value) => !!value || 'Campo obligatorio',
        min: (v) => v.length >= 8 || 'Minimo 8 caracteres',
      },
      nameRules: [
        (v) => !!v || 'El nombre de usuario es obligatorio',
        // (v) => (v && v.length >= 10) || 'El nombre debe tener por lo menos 10 carateres',
      ],
      errorMessage: '',
    };
  },
  methods: {
    ...mapActions('ui', ['getAppCategories']),
    ...mapMutations('ui', ['TOGGLE_FORM_DIALOG']),
    login() {
      console.log('antes validate', this.valid);
      const isValid = this.validate();
      if (isValid === false) {
        return;
      }
      if (this.validatedCaptcha === false) {
        this.captchaError = true;
        return;
      }
      this.captchaError = false;
      const { userName, password } = this;
      this.$store.dispatch('auth/login', { userName, password })
        .then((resp) => {
          console.log('entro login then', resp);
          if (resp !== 'success') {
            this.errorMessage = resp;
          } else {
            // si el logueo es exitoso volver a recargar las
            // aplicaciones para obtener las correspondientes
            // al usuario en cuestión
            this.errorMessage = '';
            this.getAppCategories();
            // cerrar el modal
            this.TOGGLE_FORM_DIALOG(false);
          }
        })
        .catch((err) => {
          console.log('entro login catch', err);
        });
    },
    openRegisterForm() {
      this.$emit('response', 'registerItem');
    },
    openForgotPasswordForm() {
      this.$emit('response', 'forgotPassword');
    },
  },
};
</script>
<style>
.cuadro_capcha {
  width: 235px !important;
}
.text-forgot {
  font-style: italic;
  text-decoration: underline;
  text-transform: initial;
  color: #3f51b5;
}
</style>
