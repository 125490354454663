<template lang="pug">
v-card(class="mx-auto my-8 reporte-meteorologico" style="border-radius:10px")
  v-card(dark flat)
    v-card-title(
      class="pa-2 place-forecast-card-title")
      div {{placeName}}
  v-card-text(class="py-0 pb-2")
    v-skeleton-loader(
      v-if="loading"
      type="article, list-item-three-line, image")
    div(class="text-center mt-2" v-else)
      div(class="place-forecast-title mb-8") Pronóstico para {{placeName}}
        v-divider(class="my-5")
      v-row
        v-col(cols="12" md="6" class="text-center" style="min-height: 250px")
          div(class="general-info-wrapper ml-8 mb-8")
            span(class="general-info-item")
              |Temperatura máxima: <b>{{tempData.maxVal}}</b> el {{tempData.dateMax}}
            br
            span(class="general-info-item")
              |Temperatura mínima: <b>{{tempData.minVal}}</b> el {{tempData.dateMin}}
            br
            span(class="general-info-item")
              |Viento máximo: <b>{{windData.maxVal}}</b> el {{windData.dateMax}}
            br
            span(class="general-info-item")
              |Viento mínimo: <b>{{windData.minVal}}</b> el {{windData.dateMin}}
        v-col(cols="12" md="6" class="text-center" style="min-height: 250px")
          span(class="mt-4 title_precipitacion") <b>Precipitaciones mayores a 0mm</b>
          br
          v-list(class="text-caption scrollable" style="max-height: 200px")
            v-list-item(
              v-for="(item, i) in rainData"
              :key="i")
              v-list-item-content
                v-list-item-title <b>{{item.value}}</b> para el {{item.date}}
      //- descomentar para ver timeline
      //- h4 Línea de Tiempo
      //- forecast-timeline(:dates="labels" :variables="variables" :selectedVars="['PSFC', 'T2']")
      v-divider(class="my-5")
      div()
        div
          h2 Gráficas de barras
          h4 En esta sección usted puede agregar las variables que desea a
            |  la gráfica de barras, para comparar entre distintas series
          br
          v-select(
            style="width:100%"
            v-model="selectedBarChart"
            :items="variables"
            item-text="name"
            item-value="variable"
            chips
            label="Seleccione Variables"
            multiple
            @change="selectedBarChange"
            solo)
          forecast-bar-chart(
            :graphLabels="labels"
            :variables="variables"
            style="height:400px")
          br
          br
        div
          h2 Gráficas de líneas
          h4 En esta sección usted puede agregar las variables que desee a la
            |  gráfica de líneas para comparar distintas series
          br
          v-select(
            style="width:100%"
            v-model="selectedLineChart"
            :items="variables"
            item-text="name"
            item-value="variable"
            chips
            label="Seleccione Variables"
            multiple
            @change="selectedLineChange"
            solo)
          forecast-line-chart(
            :graphLabels="labels"
            :variables="variables"
            :use-format="false"
            style="height:400px")
          br
          br
        div(class="mt-4")
          h2 Velocidad del viento y barbas
          h4 En este gráfico usted encontrará las barbas que representan la velocidad
            |  y dirección del viento
          br
          forecast-barb-chart(
            :southern-wind="variableV10"
            :zonal-wind="variableU10"
            :speed-wind="variableUuvv10"
            :initial-date="labels[0]"
            :finish-date="labels[labels.length - 1]"
            :title="placeName"
          )

</template>

<script>
import { latLng } from 'leaflet';
import {
  LMap, LWMSTileLayer, LMarker
} from 'vue2-leaflet';
import { mapMutations } from 'vuex';
import ForecastTimeline from '@/components/Forecast/ForecastTimeline.vue';
import ForecastLineChart from '@/components/Forecast/ForecastLineChart.vue';
import ForecastBarChart from '@/components/Forecast/ForecastBarChart.vue';
import ForecastBarbChart from '@/components/Forecast/ForecastBarbChart.vue';
import {
  serverApi, tempVariable, windVariable, rainVariables
} from '../config.js';
import { getToken } from '@/utils.js';

export default {
  name: 'PlaceForecast',
  components: {
    LMap,
    LMarker,
    'l-wms-tile-layer': LWMSTileLayer,
    ForecastTimeline,
    ForecastLineChart,
    ForecastBarChart,
    ForecastBarbChart,
  },
  data() {
    return {
      labels: [],
      variables: [],
      selectedBarChart: [], // rainVariable
      selectedLineChart: [tempVariable],
      loading: true,
      placeName: '',
      placeLat: null,
      placeLng: null,
      center: latLng(this.$route.query.lat, this.$route.query.lng),
      tempData: {},
      windData: {},
      rainData: [],
      rainVariable: null,
      variableU10: [],
      variableV10: [],
      variableUuvv10: [],
    };
  },
  computed: {
    marker() {
      return latLng(this.placeLat, this.placeLng);
    }
  },
  created() {
    // Actualizar las grafica de lineas con las bariables iniciales
    this.$bus.$on('line-chart-ready', () => {
      this.$bus.$emit('update-line-chart', this.selectedLineChart);
    });
  },
  async mounted() {
    // tomar los valores de la url para inicializar el componente
    this.placeName = this.$route.query.name;
    this.placeLat = this.$route.query.lat;
    this.placeLng = this.$route.query.lng;
    // Conseguir el token si existe para autenticar la petición
    const token = await getToken();
    const headers = {};
    if (token !== '') {
      headers.Authorization = `Bearer ${token}`;
    }
    // traer la información de las variables para este punto
    const response = await fetch(`${serverApi}points/${this.$route.params.point_token}`, {
      headers,
    });
    if (response.status === 200) {
      const jsonResp = await response.json();
      const variableNames = jsonResp.data.variables.map((x) => x.variable);
      [this.rainVariable] = rainVariables.filter((value) => variableNames.includes(value));
      // Agregar la variable de lluvia y actualizar el grafico de barras
      this.selectedBarChart.push(this.rainVariable);
      this.$bus.$on('bar-chart-ready', () => {
        this.$bus.$emit('update-bar-chart', this.selectedBarChart);
      });

      this.labels = jsonResp.data.dates;
      this.variables = jsonResp.data.variables;

      const variableV10 = this.variables.filter((x) => x.variable === 'V10')[0];
      this.variableV10 = variableV10 ? variableV10.data : [];

      const variableU10 = this.variables.filter((x) => x.variable === 'U10')[0];
      this.variableU10 = variableU10 ? variableU10.data : [];

      const variableUuvv10 = this.variables.filter((x) => x.variable === 'UUVV10')[0];
      this.variableUuvv10 = variableUuvv10 ? variableUuvv10.data : [];

      // obtener os datos de la precipitacion
      let rainData = this.variables.filter((x) => x.variable === this.rainVariable)[0];
      rainData = rainData.data.map((x, index) => {
        let el = {};
        if (x > 0.005) {
          el = {
            value: `${x.toFixed(2)} ${rainData.unit}`,
            date: `${this.labels[index].slice(0, 10)} a las ${this.labels[index].slice(11, 16)}`
          };
        }
        return el;
      }).filter((x) => x.date);
      this.rainData = rainData;

      // obtener valores de temperaturas máximas y mínimas
      const tempData = this.variables.filter((x) => x.variable === tempVariable)[0];
      this.tempData = this.getMaxMinVal(tempData.data, tempData.unit);

      // obtener valores de de viento máximos y mínimos
      const windData = this.variables.filter((x) => x.variable === windVariable)[0];
      this.windData = this.getMaxMinVal(windData.data, windData.unit);
      this.loading = false;

      // Actualizar las graficas con las variables iniciales
      // await this.$bus.$emit('update-bar-chart', this.selectedBarChart);
      // this.selectedBarChange();
    } else {
      const data = await response.json();
      this.SHOW_ALERT({
        level: 'error',
        message: `${response.status} - ${data || response.statusText}`
      });
    }
  },
  methods: {
    ...mapMutations('ui', ['SHOW_ALERT']),
    /* filterRainData(data) {

    }, */
    getMaxMinVal(data, unit) {
      let minVal = data[0];
      let maxVal = data[0];
      let dateMin = 0;
      let dateMax = 0;
      for (let index = 0; index < data.length; index++) {
        const element = data[index];
        if (element > maxVal) {
          maxVal = element;
          dateMax = index;
        }
        if (element < minVal) {
          minVal = element;
          dateMin = index;
        }
      }

      // obtener las fechas proporcionadas por la API
      dateMin = `${this.labels[dateMin].slice(0, 10)} a las ${this.labels[dateMin].slice(11, 16)}`;
      dateMax = `${this.labels[dateMax].slice(0, 10)} a las ${this.labels[dateMax].slice(11, 16)}`;

      minVal = `${minVal.toFixed(2)} ${unit}`;
      maxVal = `${maxVal.toFixed(2)} ${unit}`;

      return {
        minVal, maxVal, dateMin, dateMax
      };
    },
    selectedBarChange(e) {
      this.$bus.$emit('update-bar-chart', e);
    },
    selectedLineChange(e) {
      this.$bus.$emit('update-line-chart', e);
    },
  }
};
</script>
<style scoped>
.title_precipitacion {
  font-size: 20px;
}
.v-list-item {
  min-height: 20px!important;
}
.v-list-item__content {
  padding-bottom: 10px!important;
  padding-top: 0px!important;
}
</style>
